import { Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

const PaymentFail = () => {
  return (
    <>
    <div className="successcard" >
      <div 
      style={{borderRadius:"200px", height:"200px", width:"200px", margin:"0 auto",color:'#FA113D'}}
      >
        {/* <i class="warning icon" style={{color:'#FA113D'}}>!</i> */}
        <img
          style={{
                  width: "100%",
                  height: "auto",
                  margin: "auto",
                }}
                src="./images/fail gif.gif"
                alt="Fail gif"
              />
      </div>
        <h1 style={{color:'#FA113D'}}>Oops! Something went wrong.</h1> 
        <p style={{color:'#FA113D',marginBottom:'8px'}}>While trying to reserve money from your account</p>
        <Typography>
            <Link
              style={{ textDecoration: "none", color: "gray" }}
              to="/">
              <button
                style={{
                  width: "30%",
                  height: "60px",
                  fontSize: "18px",
                  margin: "auto",
                  backgroundColor: "rgb(54,225,253)",
                  backgroundImage:
                    "linear-gradient(to right top, #36e1fd, #36e1fd, #9787ff, #f055ed, #fc17fa)",
                }}
                className="btn-hover color-3">
                Home
                {/* <p style={{margin:'auto',color:'none',textAlign:'center',padding:'2px'}}>Coming Soon...</p> */}
              </button>
            </Link>
          </Typography>
      </div>
    </>
  )
}

export default PaymentFail;