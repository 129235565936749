import React from "react";
import "./style.css";
import { Stack } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <Stack
      className="footer"
      style={{
        width: "100%",
        backgroundColor: "black",
        color: "grey",
        marginTop: "100px",
        bottom: "0",
      }}>
      <Grid container spacing={2} style={{ padding: "15px" }}>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}>
          <div className="footer-logo">
            <img
              style={{ width: "100%", height: "auto" }}
              src="https://lifehisto-s3-bucket.s3.amazonaws.com/logo/footer-logo.png"
              alt="foote-logo"
            />
            <div>2023 by LifeHisto</div>
          </div>
        </Grid>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{
            margin: "auto",
            justifyContent: "center",
            textAlign: "left",
            alignItems: "center",
            fontSize: "18px",
            paddingLeft: "50px",
          }}>
          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/">
              Home
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/about">
              About Us &amp; Team
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/vision">
              Vision of Company
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/subscription">
              Subscription
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/portfolio">
              Portfolio
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/faq">
              FAQ
            </Link>
          </Typography>
        </Grid>

        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{
            margin: "auto",
            justifyContent: "center",
            textAlign: "left",
            alignItems: "center",
            fontSize: "18px",
          }}>
          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/privacy">
              Privacy Policy
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/terms">
              Terms & Conditons
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/refund">
              Refund/Cancellation Policy
            </Link>
          </Typography>

          <Typography>
            <Link
              style={{
                textDecoration: "none",
                color: "gray",
                paddingLeft: "50px",
              }}
              to="/contact">
              Contact Us
            </Link>
          </Typography>
        </Grid>

        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{
            justifyContent: "center",
            textAlign: "center",
            alignItems: "center",
          }}>
          <Stack style={{ color: "gray" }}>
            <Stack>
              <Typography style={{ fontSize: "28px", paddingBottom: "8px" }}>
                FOLLOW US
              </Typography>
            </Stack>
            <span class="background" style={{ color: "gray" }}>
              <span class="social-media-buttons" style={{ color: "gray" }}>
                <a
                  href="https://www.instagram.com/lifehisto/?igshid=MzNlNGNkZWQ4Mg%3D%3D"
                  target="_blank"
                  class="social-media-button"
                  style={{ outline: "2px solid grey" }}>
                  <img
                    style={{ filter: "grayscale(100%)" }}
                    alt="Instagram"
                    src="images/instagram.svg"
                  />
                </a>
                <a
                  href="https://twitter.com/i/flow/login?redirect_after_login=%2FHistoLife3162"
                  target="_blank"
                  class="social-media-button"
                  style={{ outline: "2px solid grey" }}>
                  <img
                    style={{ filter: "grayscale(100%)" }}
                    alt="Twitter"
                    src="images/twitter.svg"
                  />
                </a>
                <a
                  href="https://www.youtube.com/@lifehisto"
                  target="_blank"
                  class="social-media-button"
                  style={{ outline: "2px solid grey" }}>
                  <img
                    style={{ filter: "grayscale(100%)" }}
                    alt="Youtube"
                    src="images/youtube.svg"
                  />
                </a>
                <a
                  href="https://www.facebook.com/people/lifehistocom/100093182573491/?mibextid=ZbWKwL"
                  target="_blank"
                  class="social-media-button"
                  style={{ outline: "2px solid grey" }}>
                  <img
                    style={{ filter: "grayscale(100%)" }}
                    alt="Facebook"
                    src="images/facebook.svg"
                  />
                </a>
              </span>
            </span>
          </Stack>
        </Grid>
      </Grid>
      <Stack
        style={{
          margin: " auto",
          textAlign: "center",
          alignItems: "center",
          flexDirection: "row",
          padding: "10px 0px",
        }}>
        <Stack>Designed and Developed by</Stack>
        <Stack>
          <a
            style={{
              textDecoration: "none",
              color: "rgb(252,23,250)",
              margin: " 0px 2px",
            }}
            href="http://lifehisto.com/">
            LifeHisto
          </a>
        </Stack>
        |{" "}
        <Stack>
          <span class="far fa-copyright"></span> 2023 All rights Reserved.
        </Stack>
      </Stack>
    </Stack>
  );
}

export default Footer;
