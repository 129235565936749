import { useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import Navbar1 from "../Navbar1";
import Footer from "../footer";
import { Stack, Typography } from "@mui/material";
import { FacebookIcon, TwitterIcon, WhatsappIcon } from 'react-share';
import { FacebookShareButton, TwitterShareButton, WhatsappShareButton, EmailShareButton, InstapaperShareButton } from 'react-share';
import { Instagram } from "@material-ui/icons";


pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Flipbook2(props) {
  const [numPages, setNumPages] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  function pagesList() {
    var pages = [];
    for (var i = 1; i <= numPages; i++) {
      pages.push(
        <div key={i}>
          <Page pageNumber={i} width={isMobile ? window.innerWidth : 500} />
        </div>
      );
    }
    return pages;
  }


  const [shareUrl, setShareUrl] = useState(window.location.href);

  const handleShare = () => {
    if (navigator.share) {
      navigator.share({ url: shareUrl })
        .then(() => console.log('Shared successfully.'))
        .catch((error) => console.error('Error sharing:', error));
    }
  };

  return (
   <>
   <Stack style={{background: 'linear-gradient(45deg, #d2001a, #7462ff, #f482ff, #23d5ab)',backgroundSize: '300% 300%', 
  animation: 'color 12s ease-in-out infinite'}}>
   <Navbar1 />
   <Typography
        style={{ fontSize: "50px", textAlign: "center", padding: "10px", }}>
        THE CHRONICLE BOOK OF ENTITY
      </Typography>
    <Document
      file="https://lifehisto-s3-bucket.s3.amazonaws.com/PROFILE+PDF'S/Gillella+Annika%C2%A0reddy.pdf"
      onLoadSuccess={onDocumentLoadSuccess}
      className="modal-90w">
      <HTMLFlipBook
       width={isMobile ? window.innerWidth : 500}
       height={isMobile ? window.innerHeight - 200 : 707}
       showCover={true}
       mobileScrollSupport={true}
       maxShadowOpacity={0.5}
       autoSize={true}
       drawShadow={true}
       clickEventForward={true}
       useMouseEvents={true}
       renderOnlyPageLengthChange={true}
       getPageCount={1}
       className="flipbook"
        >
        {pagesList()}
      </HTMLFlipBook>
    </Document>

    <div style={{width:'50%',display:'flex',margin:'auto', textAlign:'center'}}>
      <FacebookShareButton url={shareUrl} onClick={handleShare}>
      <FacebookIcon url={shareUrl} onClick={handleShare} style={{ fontSize: "34px" }} />
      </FacebookShareButton>


      <TwitterShareButton url={shareUrl} onClick={handleShare}>
      <TwitterIcon style={{ fontSize: "34px" }} />
      </TwitterShareButton>

      <WhatsappShareButton url={shareUrl} onClick={handleShare}>
      <WhatsappIcon style={{ fontSize: "34px" }} />
      </WhatsappShareButton>

      
      <InstapaperShareButton url={shareUrl} onClick={handleShare}>
      <Instagram style={{ fontSize: "34px" }} />
      </InstapaperShareButton>

    </div>

    <Footer />
    </Stack>
   </>
    
  );
}
export default Flipbook2;
