import React, { useEffect, useState } from "react";
import { Grid, Stack } from "@mui/material";
import Marquee from "react-fast-marquee";
import Footer from "./footer";
import ContactForm from "./Pages/ContactForm";
import { Typography } from "@material-ui/core";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import "./style.css";
import Navbar from "./Navbar1";
import ScrollAdd from "./Pages/ScrollAdd.js";

const Homepage = () => {
  const images = [
    "https://lifehisto-s3-bucket.s3.amazonaws.com/Home+Page+Images/slide-img-1.jpg",
    "https://lifehisto-s3-bucket.s3.amazonaws.com/Home+Page+Images/slide-img-4.jpg",
    "https://lifehisto-s3-bucket.s3.amazonaws.com/Home+Page+Images/slide-img-3.jpg",
    "https://lifehisto-s3-bucket.s3.amazonaws.com/Home+Page+Images/slide-img-5.jpg",
    "https://lifehisto-s3-bucket.s3.amazonaws.com/Home+Page+Images/slide-img-2.jpg",
    "https://lifehisto-s3-bucket.s3.amazonaws.com/Home+Page+Images/slide-img-6.jpg",
  ];

  const [index, setIndex] = useState(0);
  const image = images[index];

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000);

    return () => {
      clearInterval(interval);
    };
  }, [images.length]);

  return (
    <>
      <Navbar />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="./images/web -logo copy.png"
      />

      <ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        // viewBox="0 0 256 256"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #36e1fd, #36e1fd, #9787ff, #f055ed, #fc17fa)",
        }}
      />


      <div className="slideshow-main-div">
        <div key={index}>
          <img
            className="slideshow-image"
            src={image}
            alt={`Image ${index + 1}`}
          />
        </div>
      </div>

      <ScrollAdd />

      <Stack>

      <Typography style={{ margin: "auto", textAlign: "center" }}>
          <h1 className="home-heading-sizes-h1" id="animate-charcter"style={{paddingTop:'8px'}}>
            The Worlds First Social Media For Children
          </h1>
        </Typography>

        <Typography style={{ margin: "auto", textAlign: "center" }}>
          <p className="home-heading-sizes-p" id="animate-charcter">
            THE CHRONICLE BOOK OF ENTITY
          </p>
        </Typography>

        <Typography
          style={{
            width: "80%",
            textAlign: "justify",
            margin: "auto",
            paddingBottom: "30px",
          }}>
          <p className="home-heading-sizes-para">
            Dear parents, we specialize in capturing the pivotal moments of your
            child's life journey and creating a sculpture portfolio entitled
            "THE CHRONICLE BOOK OF ENTITY". Our team continuously updates and
            maintains this portfolio while acting as personal public relations
            representatives for each child. We assist with registering talents,
            achieving awards or breaking records. Additionally, our services
            include maintaining an online presence by promoting activities
            through social media pages to gain sponsorships and government
            grants on behalf of your children.
          </p>
        </Typography>
      </Stack>

    
      <Grid container spacing={2} className="top-portfolio-mainbox" style={{width:'80%',margin:'auto'}}>
            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="top-portfolio-box-1">
              <a href="https://lifehisto.com/#/porfile1?id=119&user=ng2010820233643822">
                <img src="images/adharva png.png" alt="Most Liked Portfolios" />
                <h3 id="animate-charcter">Most Liked Portfolios</h3>
              </a>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="top-portfolio-box-1">
              <a href="http://localhost:3000/#/porfile1?id=38&user=ddy250920238223917">
                <img src="images/Annika-Reddy.png" alt="Most Commented Portfolios" />
                <h3 id="animate-charcter">Most Commented Portfolios</h3>
              </a>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="top-portfolio-box-1">
              <a href="http://localhost:3000/#/porfile1?id=41&user=thi250920235223240">
                <img src="images/RAHSH SETHI png.png" alt="Most Shared Portfolios" />
                <h3 id="animate-charcter">Most Shared Portfolios</h3>
              </a>
            </Grid>

            <Grid item xs={12} sm={12} md={3} lg={3} xl={3} className="top-portfolio-box-1">
              <a href="https://lifehisto.com/#/porfile1?id=43&user=hna250920238258610">
                <img src="images/RAMBOTHU RAVIT SAI KRISHNA png.png" alt="Most viewed Portfolios" />
                <h3 id="animate-charcter">Most viewed Portfolios</h3>
              </a>
            </Grid>
          </Grid>  



      <Typography style={{ margin: "auto", textAlign: "center" }}>
        <div
          id="animate-charcter"
          classNameName="home-heading-sizes"
          style={{ fontSize: "42px", marginTop: "10px" }}
        >
          Recent Activities
        </div>
      </Typography>

      <Grid
        container
        sx={{
          flexDirection: "row",
          width: "80%",
          margin: "auto",
          height: "auto",
          color: "#fff",
          // added for 2 
          textAlign:'center',
          justifyContent:'center',
          alignItems:'center',
          gap:'30px',
        }}>
        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              title="Unique Title1 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/ah7-v8lPWyI?si=LW_h1mFjDlsIWcBi" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </Stack>
        </Grid>

        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              title="Unique Title2 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/9SePOizW26w?si=NIdMIu58QiqYxT10" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"></iframe>
          </Stack>
        </Grid>

        {/* <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              title="Unique Title3 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/tmgDvHRu6d4?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"></iframe>
          </Stack>
        </Grid>

        <Grid
          item
          lg={3}
          md={3}
          sm={12}
          xs={12}
          style={{ height: "250px", padding: "10px" }}>
          <Stack>
            <iframe
              title="Unique Title4 Here"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              width="100%"
              height="200px"
              type="text/html"
              margin="auto"
              src="https://www.youtube.com/embed/tmgDvHRu6d4?autoplay=0&fs=0&iv_load_policy=3&showinfo=0&rel=0&cc_load_policy=0&start=0&end=0&origin=https://youtubeembedcode.com"></iframe>
          </Stack>
        </Grid> */}

      </Grid>

      <ContactForm />

      <Footer />
    </>
  );
};

export default Homepage;
