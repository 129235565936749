import React, { useEffect } from "react";

const InfinityPreloader = () => {
  useEffect(() => {
    // After the component is mounted, add a class to trigger the animations
    document.querySelector(".ip__worm1").classList.add("animate");
    document.querySelector(".ip__worm2").classList.add("animate");
  }, []);

  return (
    <main className="preloader-container">
      {/* Add the background image */}
      <div>
        <svg
          className="ip"
          viewBox="0 0 256 128"
          width="256px"
          height="128px"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Logo with low opacity */}
          <image
            href="https://lifehisto-s3-bucket.s3.amazonaws.com/logo/LifeHisto+Logo.png"
            x="50"
            y="32"
            width="128"
            height="64"
            opacity="0.5" // Adjust opacity as needed
          />
          <defs>
            <linearGradient id="grad1" x1="0" y1="0" x2="1" y2="0">
              <stop offset="0%" stopColor="#fc17fa" />
              <stop offset="25%" stopColor="#36e1fd" />
              <stop offset="50%" stopColor="#ff13ef" />
              <stop offset="75%" stopColor="#12d7f6" />
              <stop offset="100%" stopColor="#fff" />
            </linearGradient>
            <linearGradient id="grad2" x1="1" y1="0" x2="0" y2="0">
              <stop offset="0%" stopColor="#fff" />
              <stop offset="25%" stopColor="#12d7f6" />
              <stop offset="50%" stopColor="#ff13ef" />
              <stop offset="75%" stopColor="#36e1fd" />
              <stop offset="100%" stopColor="#fc17fa" />
            </linearGradient>
          </defs>
          <g fill="none" strokeLinecap="round" strokeWidth="16">
            <g className="ip__track" stroke="#ddd">
              <path d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56" />
              <path d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64" />
            </g>
            <g strokeDasharray="180 656">
              <path
                className="ip__worm1"
                stroke="url(#grad1)"
                strokeDashoffset="0"
                d="M8,64s0-56,60-56,60,112,120,112,60-56,60-56"
              />
              <path
                className="ip__worm2"
                stroke="url(#grad2)"
                strokeDashoffset="358"
                d="M248,64s0-56-60-56-60,112-120,112S8,64,8,64"
              />
            </g>
          </g>
        </svg>
      </div>
    </main>
  );
};

export default InfinityPreloader;
