import { Grid, Stack, Typography } from "@mui/material";
import React from "react";
import Navbar1 from "../Navbar1";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import ContactForm from "./ContactForm";
import ScrollToTop from "react-scroll-to-top";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollAdd from "./ScrollAdd";

const About = () => {
  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="./images/web -logo copy.png"
      />

      <ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #36e1fd, #36e1fd, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <ScrollAdd />

      <Typography
        style={{
          width: "80%",
          margin: "auto",
          padding: " 25px 0px",
          textAlign: "center",
        }}>
        <h1
          id="animate-charcter"
          className="home-heading-sizes"
          style={{
            textAlign: "center",
          }}>
          About Us
        </h1>

        <hr style={{ margin: "25px 0px" }}></hr>

        <Grid container>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", textAlign: "center" }}>
            <Stack style={{ margin: "auto" }}>
              <img
                style={{
                  width: "85%",
                  height: "auto",
                  margin: "auto",
                }}
                src="https://lifehisto-s3-bucket.s3.amazonaws.com/About+page/about+page+main+img.png"
                alt="About main pic"
              />
            </Stack>
          </Grid>

          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", padding: "5px" }}>
            <Typography style={{ textAlign: "justify", margin: "auto" }}>
              LifeHisto is a pioneering platform that specializes in designing
              portfolios for children, making it the first of its kind
              worldwide. Our mission is to empower parents and guardians by
              providing insights into their child's career journey while helping
              connect them with appropriate platforms to register their
              achievements on national and world records as well as special
              talent award-winning companies. From birth, we follow each child's
              unique path through life and present their story compellingly. We
              aim to create responsible citizens who strive towards reaching
              full potential while contributing positively to society. Our
              user-friendly interface provides building stats of educational
              qualifications, work experience, skill sets; enabling tracking
              progress alongside personalized recommendations for your child's
              career development needs - essential information when navigating
              today’s ever-changing job market! At LifeHisto , we understand
              capturing milestones & achievements can be challenging but vital
              in showcasing every aspect of one’s potential from an early
              age-helping plan better future prospects! Join us at Lifehisto-
              where our ultimate goal remains empowering you with accurate
              up-to-date data-driven knowledge about your Childs' professional
              growth opportunity options – so they become tomorrow successful
              leaders starting now!
            </Typography>
          </Grid>
        </Grid>

        <h1
          id="animate-charcter"
          className="home-heading-sizes"
          style={{
            textAlign: "center",
          }}>
          About Team
        </h1>
        <hr style={{ margin: "25px 0px" }}></hr>
        <Typography style={{ textAlign: "justify" }}>
          We are a devoted team of professionals who have collaborated to
          construct an exceptional resource for guardians and caregivers. Our
          group is dedicated to delivering the most up-to-date research,
          practical recommendations, and stimulating activities that facilitate
          children's cultivation of healthy habits while attaining their maximum
          potential. At our website named Lifehesto, we aim towards providing a
          comprehensive platform that encompasses all dimensions pertaining to a
          child's development; these include physical health, emotional
          well-being as well as cognitive growth. We furnish expert articles on
          various topics ranging from nutrition tips to exercise strategies
          along with interactive tools and games aimed at augmenting children’s
          learning skills in addition reinforcing their overall personal
          advancement.
        </Typography>

        <h1
          id="animate-charcter"
          className="home-heading-sizes"
          style={{
            textAlign: "center",
            marginTop: "20px",
          }}>
          Founder
        </h1>

        <hr style={{ margin: "25px 0px" }}></hr>

        <Grid container>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", textAlign: "center" }}>
            <div className="profile-wrapper" style={{ margin: "auto" }}>
              <div className="profile">
                <div className="profile-image">
                  <img
                    src="https://lifehisto-s3-bucket.s3.amazonaws.com/About+page/karthik.jpg"
                    alt="Founder Profile Pic"
                  />
                </div>
                <ul className="social-icons">
                  <li>
                    <a href="https://instagram.com/karthikreddy05?igshid=NzZhOTFlYzFmZQ==" title="Instagram">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <rect
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                      </svg>
                    </a>
                  </li>

                  <li>
                    <a
                      href="https://www.facebook.com/karthik.reddy.5680899?mibextid=LQQJ4d"
                      title="Facebook">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <path
                          d="M8.258,4.458c0-0.144,0.02-0.455,0.06-0.931c0.043-0.477,0.223-0.976,0.546-1.5c0.32-0.522,0.839-0.991,1.561-1.406
                C11.144,0.208,12.183,0,13.539,0h3.82v4.163h-2.797c-0.277,0-0.535,0.104-0.768,0.309c-0.231,0.205-0.35,0.4-0.35,0.581v2.59h3.914
                c-0.041,0.507-0.086,1-0.138,1.476l-0.155,1.258c-0.062,0.425-0.125,0.819-0.187,1.182h-3.462v11.542H8.258V11.558H5.742V7.643
                h2.516V4.458z"
                        />
                      </svg>
                    </a>
                  </li>

                  {/* <li>
                    <a href="#twitter" title="Twitter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-twitter">
                        <path
                          d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 
                                            10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5
                                            4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                      </svg>
                    </a>
                  </li> */}
                  

                  <li>
                    <a href="#linkedin" title="Linkedin">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"></path>
                        <path
                          d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 
                                             2 0 0 1 -2 -2z"></path>
                        <path d="M8 11l0 5"></path>
                        <path d="M8 8l0 .01"></path>
                        <path d="M12 16l0 -5"></path>
                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
                <div className="profile-name" style={{ textAlign: "center" }}>
                  <h2>Dr.Padala Karthik Reddy</h2>
                  <div className="profile-bio">Business Head</div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", padding: "5px" }}>
            <Typography style={{ textAlign: "justify" }}>
              {/* <h1>Dr. Padala Karthik Reddy</h1> */}
              Dr. Padala Karthik Reddy is a multifaceted individual with
              exceptional accomplishments in various fields of study and sports.
              Born on July 14th, 1987, in Hyderabad, he received his primary
              education from St. Thomas Public School and MNR School
              Vishakapatnam before pursuing higher studies that culminated into
              Bachelor's and Master's degrees in Business Management followed by
              a Doctorate degree specializing in Sports Management with
              particular emphasis on Chess. Apart from being an accomplished
              academician, Dr.Reddy has also established himself as an
              internationally recognized chess player who holds both bronze
              medals at All India Universities competitions along with setting
              world records for organizing the first-ever car tournament prize
              to honor the Man of Tournament title. Furthermore, Dr.Reddy
              possesses advanced para-piloting skills alongside specialized
              training across multiple adventure sports such as beach kayaking;
              trekking; hiking; beach swimming & wildlife safaris among others -
              making him a well-known enthusiast within these circles too! As an
              entrepreneur working closely with civil industries' food chains or
              sport centers alike - promoting children’s extracurricular
              activities remains one of his top priorities since it helps them
              stay motivated while inspiring them towards their goals through
              unique approaches tailored specifically around each child’s
              special talents. Reduction screens time is another area where
              Dr.Reddy has been actively advocating for healthier living amongst
              young individuals by engaging them more often than not away from
              digital devices using other fun-filled outdoor activities instead!
              As someone whose travel experiences have taken him far beyond
              familiar boundaries exploring some of this planet’s most beautiful
              destinations worldwide combining personal passions like traveling
              together staying fit goes hand-in-hand helping promote active
              lifestyles altogether! In conclusion: With all aforementioned
              achievements representing just small glimpses into what makes up
              this remarkable personality named “Dr.Padala Karthik Reddy,” there
              can be no doubt about why younger generations would find
              inspiration whenever they hear stories surrounding such driven
              individuals – truly motivational figures!
            </Typography>
          </Grid>
        </Grid>

        <h1
          id="animate-charcter"
          className="home-heading-sizes"
          style={{
            textAlign: "center",
            marginTop: "20px",
          }}>
          Team
        </h1>

        <hr style={{ margin: "25px 0px" }}></hr>

        <Grid container>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", textAlign: "center" }}>
            <div className="profile-wrapper" style={{ margin: "auto" }}>
              <div className="profile">
                <div className="profile-image">
                  <img
                    src="https://lifehisto-s3-bucket.s3.amazonaws.com/Harish.jpeg"
                    alt="Harish Pampana Profile Pic"
                  />
                </div>
                <ul className="social-icons">
                  <li>
                    <a href="https://instagram.com/mr.lucifeer_?igshid=OGQ5ZDc2ODk2ZA==" title="Instagram">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <rect
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                      </svg>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#twitter" title="Twitter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-twitter">
                        <path
                          d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 
                                            10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5
                                            4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                      </svg>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.facebook.com/harish.pampana.82?mibextid=LQQJ4d"
                      title="Facebook">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <path
                          d="M8.258,4.458c0-0.144,0.02-0.455,0.06-0.931c0.043-0.477,0.223-0.976,0.546-1.5c0.32-0.522,0.839-0.991,1.561-1.406
                C11.144,0.208,12.183,0,13.539,0h3.82v4.163h-2.797c-0.277,0-0.535,0.104-0.768,0.309c-0.231,0.205-0.35,0.4-0.35,0.581v2.59h3.914
                c-0.041,0.507-0.086,1-0.138,1.476l-0.155,1.258c-0.062,0.425-0.125,0.819-0.187,1.182h-3.462v11.542H8.258V11.558H5.742V7.643
                h2.516V4.458z"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/pampana-harish-15b9ba23a/" title="Linkedin">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"></path>
                        <path
                          d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 
                                             2 0 0 1 -2 -2z"></path>
                        <path d="M8 11l0 5"></path>
                        <path d="M8 8l0 .01"></path>
                        <path d="M12 16l0 -5"></path>
                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
                <div className="profile-name" style={{ textAlign: "center" }}>
                  <h2>Harish Pampana</h2>
                  <div className="profile-bio">Developer</div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", padding: "5px" }}>
            <Typography style={{ textAlign: "justify" }}>
              Greetings, my name is Harish Pampana, and I am honored to be a
              member of the esteemed LifeHisto team as a developer. As an
              experienced developer, I possess a strong passion for developing
              robust software solutions that cater to our users' unique needs
              while ensuring efficiency. My expertise lies in solving complex
              challenges by employing innovative approaches and delivering
              cutting-edge results that keep up with industry standards. In
              addition to continuously expanding my knowledge base through
              learning new skills relevant in today's ever-evolving tech
              landscape. Aside from work-related activities, traveling has been
              one of my interests because it broadens horizons and provides
              fresh perspectives on life situations- stimulating creativity
              while enhancing problem-solving abilities significantly.
              Furthermore, playing chess helps me sharpen analytical thinking
              processes required when dealing with intricate problems; watching
              movies also gives me joy since storytelling inspires deep emotions
              within myself which are useful particularly when working
              creatively. During academic studies at B.Tech program level four
              consecutive years were elected class representative due leadership
              prowess coupled with organizational finesse - this helped improve
              collaboration efforts among peers thereby improving communication
              channels across teams overall leading towards better outcomes
              achieved together effectively & efficiently. Actively seeking ways
              continually grow professionally leveraging strengths embracing
              emerging trends methodologies best practices drive innovation
              success-driven culture prioritized user satisfaction always top
              priority goal aim accomplish here at LifeHisto along colleagues
              exciting journey ahead. Thank you visiting website looking forward
              collaborating achieve remarkable achievements create future filled
              innovations growth prospects!
            </Typography>
          </Grid>
        </Grid>

        <hr style={{ margin: "25px 0px" }}></hr>

        <Grid container>
          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", textAlign: "center" }}>
            <div className="profile-wrapper" style={{ margin: "auto" }}>
              <div className="profile">
                <div className="profile-image">
                  <img
                    src="https://lifehisto-s3-bucket.s3.amazonaws.com/About+page/Mohammed+Karimulla.jpg"
                    alt="Karimulla Profile Pic"
                  />
                </div>
                <ul className="social-icons">
                  <li>
                    <a href="https://instagram.com/mohammed_karimulla0902?igshid=OGQ5ZDc2ODk2ZA==" title="Instagram">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <rect
                          x="2"
                          y="2"
                          width="20"
                          height="20"
                          rx="5"
                          ry="5"></rect>
                        <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z"></path>
                        <line x1="17.5" y1="6.5" x2="17.51" y2="6.5"></line>
                      </svg>
                    </a>
                  </li>
                  {/* <li>
                    <a href="#twitter" title="Twitter">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        stroke-width="2"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        className="feather feather-twitter">
                        <path
                          d="M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 
                                            10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5
                                            4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z"></path>
                      </svg>
                    </a>
                  </li> */}
                  <li>
                    <a
                      href="https://www.facebook.com/YourFacebookPage"
                      title="Facebook">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <path
                          d="M8.258,4.458c0-0.144,0.02-0.455,0.06-0.931c0.043-0.477,0.223-0.976,0.546-1.5c0.32-0.522,0.839-0.991,1.561-1.406
                C11.144,0.208,12.183,0,13.539,0h3.82v4.163h-2.797c-0.277,0-0.535,0.104-0.768,0.309c-0.231,0.205-0.35,0.4-0.35,0.581v2.59h3.914
                c-0.041,0.507-0.086,1-0.138,1.476l-0.155,1.258c-0.062,0.425-0.125,0.819-0.187,1.182h-3.462v11.542H8.258V11.558H5.742V7.643
                h2.516V4.458z"
                        />
                      </svg>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.linkedin.com/in/md-karimulla-a1aaa0178" title="Linkedin">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        stroke-width="2"
                        stroke="currentColor"
                        fill="none"
                        stroke-linecap="round"
                        stroke-linejoin="round">
                        <path
                          stroke="none"
                          d="M0 0h24v24H0z"
                          fill="none"></path>
                        <path
                          d="M4 4m0 2a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v12a2 2 0 0 1 -2 2h-12a2 
                                             2 0 0 1 -2 -2z"></path>
                        <path d="M8 11l0 5"></path>
                        <path d="M8 8l0 .01"></path>
                        <path d="M12 16l0 -5"></path>
                        <path d="M16 16v-3a2 2 0 0 0 -4 0"></path>
                      </svg>
                    </a>
                  </li>
                </ul>
                <div className="profile-name" style={{ textAlign: "center" }}>
                  <h2>Mohammed Karimulla</h2>
                  <div className="profile-bio">(Desginer).</div>
                </div>
              </div>
            </div>
          </Grid>

          <Grid
            item
            sm={12}
            md={6}
            lg={6}
            xl={6}
            style={{ margin: "auto", padding: "5px" }}>
            <Typography style={{ textAlign: "justify" }}>
              Greetings, I am Mohammed Karimulla, and I am delighted to be a
              part of the esteemed LifeHisto team as a Graphic Designer. As I
              step into this role, my dedication lies in crafting robust and
              efficient software solutions tailored to our users' distinct needs
              with utmost enthusiasm. My fervour for overcoming intricate
              challenges ignites a passion for developing innovative approaches,
              leading to cutting-edge results. I remain committed to staying
              abreast of industry designs and continuously honing my skills for
              personal and professional growth. In my leisure time, I indulge in
              exploring new horizons through travel, which fosters fresh
              perspectives, refining my creativity and problem-solving
              abilities. Furthermore, playing chess sharpens my analytical
              thinking and decision-making proficiency, skills that prove
              invaluable in both my personal and professional life. My
              leadership qualities and organizational skills were evident during
              my academic journey, where I served as the Class Representative
              for four consecutive years in the B.Tech program. This experience
              taught me the significance of effective collaboration and seamless
              communication within teams. As a professional, I thrive on a
              proactive approach, constantly seeking improvement through
              emerging technologies and industry best practices. By doing so, I
              contribute significantly to LifeHisto's success story. I am
              thrilled to be part of an exceptional team dedicated to delivering
              outstanding outcomes that leave lasting impressions on end users.
              Innovation is at the core of our mission, ensuring user
              satisfaction remains our top priority, guiding every step of our
              exciting journey. Thank you for visiting our website; together,
              let's embark on a path filled with growth opportunities fueled by
              innovation!
            </Typography>
          </Grid>
        </Grid>
      </Typography>

      <ContactForm />

      <Footer />
    </>
  );
};

export default About;
