import React from 'react'
import Marquee from 'react-fast-marquee';

const ScrollAdd = () => {
  return (
    <Marquee
        style={{
          backgroundColor: "#fff",
          color: "#fc17fa",
          padding: "12px",
          // fontFamily:'Pacifico',
          boxShadow:
            "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
        }}
        gradient={false}
        speed={40}>
        <h4>
          <span className='scroll-text-gradent' style={{ marginRight: "100px" }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>{" "}
          <span className='scroll-text-gradent' style={{ marginRight: "100px", }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>{" "}
          <span className='scroll-text-gradent' style={{ marginRight: "100px" }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>{" "}
          <span className='scroll-text-gradent' style={{ marginRight: "100px",  }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>{" "}
          <span style={{ marginRight: "100px" }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>{" "}
          <span className='scroll-text-gradent' style={{ marginRight: "100px",  }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>
          <span className='scroll-text-gradent' style={{ marginRight: "100px" }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>
          <span className='scroll-text-gradent' style={{ marginRight: "100px",  }}>
            "Design Your Child's Life History Book, It Will Turn Into An Future Encyclopedia Of Your Kid. Subscribe @ just ₹299 per annum."
          </span>
        </h4>
      </Marquee>
  )
}

export default ScrollAdd;