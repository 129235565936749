const utilController = {
  textCapitalise: function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  },
  getQueryParams: (history, param) => {
    // pass the history object as argument
    if (!history) throw new Error("History object is undefined");
    const urlParams = new URLSearchParams(history.search);
    const payload = urlParams.get(param);
    return payload;
  },
  validateFields: (userForm, fields) => {
    return fields?.some((value) => {
      return userForm[value] === "";
    });
  },
};

export default utilController;
