class ConfigAPIURL {
  static baseUrl = "https://backend.lifehisto.com";
  static postDetails = this.baseUrl + "/api/new_post/get/list/";
  static postList = this.baseUrl + "/api/new_post/main_pic/admin/"; //get
  static induvidualPostDetails = this.baseUrl + "/api/new_post/pdf/admin/"; //post
  static paymentGateway = this.baseUrl + "/api/admin/ccavRequestHandler/"; //post

}

export default ConfigAPIURL;
