import React, { useState } from "react";
import Navbar1 from "../Navbar1";
import Footer from "../footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import "../style.css";
import ScrollAdd from "./ScrollAdd";
import PaymentSuccess from "../Paymentpages/PaymentSuccess";
import PaymentFail from "../Paymentpages/PaymentFail";
import ConfigAPIURL from "../../config/ConfigApiUrl";

const NewSubscription = () => {
  const [data, setData] = useState({
    name: "",
    dob: "",
    birth_place: "",
    father_name: "",
    mother_name: "",
    grand_parents_names: "",
    phone_number: "",
    alternative_phone_number: "",
    email: "",
    social_media_links: "",
    upload_first_birthday_pictures: "",
    birthday_picture_discretion: "",
    upload_event_pictures: "",
    picture_discretion: "",
    memorable_moments: "",
    memories_discretion: "",
    Upload_family_pictures: "",
    about_family: "",
    more_details: "",
    payments: "",
    payment_id: "",
    payment_status: "",
    date_of_payment: new Date()
      .toLocaleDateString("en-in", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
      })
      ?.replaceAll("/", "-"),
    payment_details: "",
  });

  console.log(data,"this is data");

  const sendMail = (mailId) => {
    var formdata = new FormData();
    formdata.append("to", mailId);

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch("https://backend.lifehisto.com/api/email/sent/", requestOptions);
  };

  const fetchPaymentUrl = async (order_id) => {
    var formdata = new FormData();
    formdata.append("order_id", order_id);
    formdata.append("billing_name", "ravi");
    formdata.append("billing_address", "Hyderabad");
    formdata.append("billing_city", "Hyderabad");
    formdata.append("billing_state", "Telangana");
    formdata.append("billing_zip", "500035");
    formdata.append("billing_tel", "9676008292");
    formdata.append("billing_email", "ravi.kiran.25494@gmail.com");
    formdata.append("order_summary", "bill pay");
    formdata.append("merchant_param1", "customer");
    formdata.append("merchant_param2", "customer");
    formdata.append(
      "redirect_url",
      "http://52.91.230.33:8001/api/admin/ccavResponseHandler/"
    );
    formdata.append("cancel_url", "https://lifehisto.com");
    formdata.append("amount", "299");
    formdata.append("merchant_id", "2751434");
    formdata.append("currency", "INR");
    formdata.append("language", "EN");
    formdata.append("delivery_name", "Sam");
    formdata.append("delivery_address", "Vile Parle");
    formdata.append("delivery_city", "Mumbai");
    formdata.append("delivery_state", "Maharashtra");
    formdata.append("delivery_zip", "400038");
    formdata.append("delivery_country", "India");
    formdata.append("merchant_param3", "additional Info.");
    formdata.append("merchant_param4", "additional Info.");
    formdata.append("merchant_param5", "additional Info.");
    formdata.append("promo_code", "");
    formdata.append("delivery_tel", "0221234321");
    formdata.append("customer_identifier", "");
    formdata.append("billing_country", "India");
    formdata.append("integration_type", "iframe_normal");

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    const response = await fetch(ConfigAPIURL.paymentGateway, requestOptions);
    return await response?.json();
  };

  const handleClick = async (event) => {
    event.preventDefault();

    try {
      const formattedData = {
        ...data,
        dob: formatDate(data.dob), // Format date of birth to "DD-MM-YYYY" format
      };
      var formdata = new FormData();

      for (var key in formattedData) {
        if(Array.isArray(formattedData[key])){
          console.log("this is array value",formattedData[key]);
          for (let i=0 ; formattedData[key]?.length > i; i++) {
            console.log("this is array append",formattedData[key][i]);
            formdata.append(key, formattedData[key][i]);
          }
        }else {

          formdata.append(key, formattedData[key]);
        }
      }
      console.log(formdata,"this is form data");
      // return
      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      console.log("Formatted Data:", formdata); // Display the formatted data in the console

      // const response = await axios.post(
      //   "http://backend.lifehisto.com/api/new_subscriber/create/",
      //   // formattedData,
      //   // {
      //   //   headers: {
      //   //     "Content-Type": "application/json",
      //   //   },
      //   // }
      // );
      const response = await fetch(
        "https://backend.lifehisto.com/api/new_subscriber/create/",
        requestOptions
      );

      if (response.status === 200) {
        console.log("Data sent successfully!");
        window.alert("Data sent successfully!");
        sendMail(data?.email);
        resetFormData();

        const result = await response.json();
        const user_id = result?.result?.user_id;
        const paymentUrl = await fetchPaymentUrl(user_id);
        const ccavenueUrl = paymentUrl?.url;
        const form = document.createElement("form");
        console.log(ccavenueUrl,"this is ccavenue url");
        window.open(ccavenueUrl); // finally open the window url
      } else {
        console.log("Error occurred while sending data.");
        window.alert("Error occurred while sending data.");
      }
    } catch (error) {
      console.log("Error occurred while sending data:", error);
      window.alert("Error occurred while sending data.");
    }
  };
  console.log(data,"this is data");

  const formatDate = (dateString) => {
    if (!dateString) {
      return ""; // Return an empty string or handle the empty case as per your requirements
    }

    const [year, month, day] = dateString.split("-");
    return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}`;
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  console.log(data, "this is file");

  const handleFileChange = (event) => {
    const file = event.target.files
    setData((prevState) => ({
      ...prevState,
      [event.target.name]: [...file],
    }));
  };

  const resetFormData = () => {
    setData((prevState) => ({
      ...prevState,
      name: "",
      dob: "",
      birth_place: "",
      father_name: "",
      mother_name: "",
      grand_parents_names: "",
      phone_number: "",
      alternative_phone_number: "",
      email: "",
      social_media_links: "",
      upload_first_birthday_pictures: "",
      birthday_picture_discretion: "",
      upload_event_pictures: "",
      picture_discretion: "",
      memorable_moments: "",
      memories_discretion: "",
      Upload_family_pictures: "",
      about_family: "",
      more_details: "",
      payments: "",
      payment_id: "",
      payment_status: "",
      date_of_payment: "20-01-1998",
      payment_details: "",
    }));
  };

  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto. How can we help?"
        avatar="./images/web-logo-copy.png"
      />

      <ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #36e1fd, #36e1fd, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <ScrollAdd />

      <div
        className="newsub"
        style={{
          display: "flex",
          justifyContent: "center",
          margin: "18px",
          textAlign: "center",
        }}>
        <div className="container">
          <div className="brand-logo"></div>
          <div
            className="brand-title"
            id="animate-charcter"
            style={{ margin: "auto", textAlign: "center" }}>
            NEW SUBSCRIBER
          </div>
          <div className="inputs">
            <form>
              <label>NAME</label>
              <input
                value={data.name}
                onChange={handleInputChange}
                type="text"
                name="name"
                placeholder="Child Name"
              />

              <label>DATE OF BIRTH (Children)</label>
              <input
                value={data.dob}
                onChange={handleInputChange}
                type="date"
                name="dob"
                placeholder="Date of Birth"
                required
              />

              <label>BIRTH PLACE (Children)</label>
              <input
                value={data.birth_place}
                onChange={handleInputChange}
                type="text"
                name="birth_place"
                placeholder="Birth place"
              />

              <label>FATHER NAME</label>
              <input
                value={data.father_name}
                onChange={handleInputChange}
                type="text"
                name="father_name"
                placeholder="Father Name"
                required
              />

              <label>MOTHER NAME</label>
              <input
                value={data.mother_name}
                onChange={handleInputChange}
                type="text"
                name="mother_name"
                placeholder="Mother Name"
                required
              />

              <label>GRAND PARENTS NAMES</label>
              <input
                value={data.grand_parents_names}
                onChange={handleInputChange}
                type="text"
                name="grand_parents_names"
                placeholder="Grand Parents Names (paternal, maternal)"
                required
              />

              <label>PHONE NUMBER</label>
              <input
                value={data.phone_number}
                onChange={handleInputChange}
                type="text"
                name="phone_number"
                placeholder="Phone Number"
                required
              />

              <label>ALTERNATIVE PHONE NUMBER</label>
              <input
                value={data.alternative_phone_number}
                onChange={handleInputChange}
                type="text"
                name="alternative_phone_number"
                placeholder="Alternative Phone Number"
              />

              <label>MAIL ID (Parent/children)</label>
              <input
                value={data.email}
                onChange={handleInputChange}
                type="email"
                name="email"
                placeholder="Mail Id"
                required
              />

              <label>SOCIAL MEDIA LINKS</label>
              <input
                value={data.social_media_links}
                onChange={handleInputChange}
                type="text"
                name="social_media_links"
                placeholder="Instagram ID, FaceBook ID..."

              />

              <label>UPLOAD 1st BIRTH DAY PICTURES</label>
              <input
                type="file"
                name="upload_first_birthday_pictures"
                onChange={handleFileChange}
                multiple  
                required
              />

              <input
                value={data.birthday_picture_discretion}
                onChange={handleInputChange}
                type="text"
                name="birthday_picture_discretion"
                placeholder="Pictures Description"
                required
              />

              <label className="newsubscription-text-css">
                UPLOAD SCHOOL/CULTURAL/SPORTS/TOURS/TRAVELS PICTURES
              </label>
              <input
                type="file"
                name="upload_event_pictures"
                onChange={handleFileChange}
                multiple
                required
              />

              <input
                value={data.picture_discretion}
                onChange={handleInputChange}
                type="text"
                name="picture_discretion"
                placeholder="Pictures Description (School, Cultural...)"
                required
              />

              <label>MEMORABLE MOMENTS</label>

              <input
                type="file"
                name="memorable_moments"
                onChange={handleFileChange}
                multiple
                required
              />

              <input
                value={data.memories_discretion}
                onChange={handleInputChange}
                type="text"
                name="memories_discretion"
                placeholder="Description (Memorable Moments)"
                required
              />

              <label>UPLOAD FAMILY PICTURES</label>

              <input
                type="file"
                name="Upload_family_pictures"
                onChange={handleFileChange}
                multiple
                required
              />

              <input
                value={data.about_family}
                onChange={handleInputChange}
                type="text"
                name="about_family"
                placeholder="About Family..."
                required
              />

              <input
                value={data.more_details}
                onChange={handleInputChange}
                style={{ height: "150px" }}
                name="more_details"
                placeholder="More Details...."
                required
              />

              <button
                onClick={handleClick}
                className="btn-hover color-3"
                type="button">
                Subscribe ₹ 299/-
              </button>
              <p></p>
            </form>
          </div>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default NewSubscription;
