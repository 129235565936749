import React from 'react';
import utilController from '../../utils/Utilcontroller';
import { useLocation } from 'react-router-dom';
import PaymentSuccess from '../Paymentpages/PaymentSuccess';
import PaymentFail from '../Paymentpages/PaymentFail';

const Summary = () => {
  const navigate = useLocation()
  const status = utilController.getQueryParams(navigate,"status")
  if(status?.toLowerCase() === "completed/"){
    return <PaymentSuccess />
  } else {
    return <PaymentFail />
  }
  return (
    <div>
      <h1>Payment Summary Page</h1>
      <PaymentSuccess />
      {/* Add your payment summary content here */}
    </div>
  );
};

export default Summary;
