import React, { useState } from "react";
import Typography from "@mui/material/Typography";
import Navbar1 from "../Navbar1";
import { Grid, Stack } from "@mui/material";
import Marquee from "react-fast-marquee";
import Footer from "../footer";
import { FloatingWhatsApp } from "react-floating-whatsapp";
import ScrollToTop from "react-scroll-to-top";
import ScrollAdd from "./ScrollAdd";

const FAQ = () => {
  const [activeDropdown, setActiveDropdown] = useState(null);

  const toggleDropdown = (faqId) => {
    setActiveDropdown(activeDropdown === faqId ? null : faqId);
  };

  const faqs = [
    {
      id: 1,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+1%402x%402x.png",
      answer:
        "Giving our child celebrity status makes them more responsible and 90% of people intend to protect their character.",
    },
    {
      id: 2,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+2%402x%402x.png",
      answer:
        "By developing a portfolio and sharing it with friends, family, and professionals, children receive appreciation and motivation to work hard towards their career growth. PORTFOLIOS ARE THE WAY TO REACH CORPORATE AND EDUCATION INSTITUTIONS FOR SPONSORSHIPS AND GOVERNMENTS GRANSTS.",
    },
    {
      id: 3,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+3%402x%402x.png",
      answer:
        "LifeHisto is the first social media platform in India that helps in developing childrens portfolios. The subscription fee is 299.as we charge businesses that partner with us, such as national and international awards, record companies, sports academies, artists, cultural and educational equipment factories/industries, sports, cultural, talk shows, and seminar event organizers.",
    },
    {
      id: 4,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+4%402x%402x.png",
      answer:
        "A Parents or legal guardians can create accounts for their children on LifeHisto, and they are the ones who have control over the content shared on the platform.",
    },
    {
      id: 5,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+5%402x%402x.png",
      answer:
        "Yes, parents can share the LifeHisto profile of their child with potential employers, colleges, or anyone else who may be interested in learning more about the childs achievements and talents.",
    },
    {
      id: 6,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+6%402x%402x.png",
      answer:
        "Yes, family members can access and contribute to a childs LifeHisto profile, allowing them to stay connected and engaged in the childs life journey.",
    },
    {
      id: 7,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+7%402x%402x.png",
      answer:
        "LifeHisto provides a platform for parents and children to connect and share their experiences, creating a sense of community and support among families.",
    },
    {
      id: 8,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+8%402x%402x.png",
      answer:
        "LifeHisto provides a way for parents to document and preserve their childs life journey, which can encourage them to be more present and involved in their childs life.",
    },
    {
      id: 9,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+9%402x%402x.png",
      answer:
        "No, LifeHisto is not a regular social media site. We only take career growth-oriented content, and we connect the right requirements to the right providers without taking any commercials",
    },
    {
      id: 10,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+10%402x%402x.png",
      answer:
        "LifeHisto has strict privacy policies and employs state-of-the-art security measures to protect childrens information from any unauthorized access or misuse.",
    },
    {
      id: 11,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+11%402x%402x.png",
      answer:
        "No, LifeHisto is for all children regardless of their talents or interests. Its a platform for capturing and celebrating their life journey, and the milestones achieved along the way.",
    },
    {
      id: 12,
      question:
        "https://lifehisto-s3-bucket.s3.amazonaws.com/faq+page/faq+shapes+12%402x%402x.png",
      answer:
        "Parents or legal guardians can create accounts for their children on LifeHisto, and they are the ones who have control over the content shared on the platform.",
    },
  ];

  return (
    <>
      <Navbar1 />

      <FloatingWhatsApp
        phoneNumber="9293225555"
        accountName="LifeHisto"
        allowEsc
        allowClickAway
        notification
        notificationSound
        chatMessage="Welcome to LifeHisto How can we help?"
        avatar="https://lifehisto-s3-bucket.s3.amazonaws.com/logo/web+-logo+copy.png"
      />

      <ScrollToTop
        smooth
        color="black"
        width="100%"
        height="23px"
        style={{
          margin: "auto",
          position: "fixed",
          background:
            "linear-gradient(to right top, #36e1fd, #36e1fd, #9787ff, #f055ed, #fc17fa)",
        }}
      />

      <ScrollAdd />

      <Grid container style={{ width: "100%", height: "auto" }}>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          style={{ margin: "auto", textAlign: "center", padding: "15px" }}>
          <h1 id="animate-charcter" style={{ fontSize: "45px" }}>
            FAQ
          </h1>
          <p style={{ textAlign: "justify", padding: "30px" }}>
            Welcome to the FAQ page for LifeHisto! Here, we have compiled a list
            of frequently asked questions to help address any queries you may
            have about our services and offerings. If you don't find the answer
            you're looking for, please feel free to contact our support team for
            further assistance.
          </p>
        </Grid>

        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="faq-mbox-2">
          <img src="./images/faq-main-gif.gif" alt="faq-main-gif" />
        </Grid>
      </Grid>

      <Typography
        style={{
          margin: "auto",
          padding: "15px",
          textAlign: "center",
        }}>
        <h1 id="animate-charcter" className="home-heading-sizes">
          Most Frequently Asked Questions
        </h1>
      </Typography>

      <div>
        {faqs.map((faq) => (
          <div style={{ margin: "auto", textAlign: "center" }} key={faq.id}>
            <img
              src={faq.question}
              alt={`FAQ ${faq.id}`}
              onClick={() => toggleDropdown(faq.id)}
              style={{ cursor: "pointer", width: "50%",filter: 'drop-shadow(5px 5px 5px #222)' }}
            />
            {activeDropdown === faq.id && (
              <p
                className="faq-answer"
                style={{
                  width: "40%",
                  margin: "auto",
                  textAlign: "justify",
                  color: "#000",
                  fontSize: "20px",
                  backgroundColor: "#fff",
                  padding: "30px",
                  boxShadow: "rgba(0, 0, 0, 0.15) 0px 5px 15px 0px",
                }}>
                {faq.answer}
              </p>
            )}
          </div>
        ))}
      </div>

      <Stack
        style={{
          width: "80%",
          margin: "auto",
          marginTop: "100px",
          marginBottom: "100px",
        }}>
        <Grid
          container
          style={{
            width: "100%",
            height: "auto",
            margin: "auto",
            textAlign: "center",
          }}>
          <Grid item sm={12} md={4} lg={4} xl={4}>
            <div className="faq-quot"
              >
              <img
                style={{ width: "100%", height: "100%" }}
                src="./images/How to manage.png"
                alt="How to manage.png"
              />
            </div>
          </Grid>

          <Grid item sm={12} md={4} lg={4} xl={4}>
            <div className="faq-quot"
              >
              <img
                style={{ width: "100%", height: "100%" }}
                src="./images/What to do.png"
                alt="What to do.png"
              />
            </div>
          </Grid>
          <Grid item sm={12} md={4} lg={4} xl={4}>
            <div className="faq-quot"
              >
              <img
                style={{ width: "100%", height: "100%" }}
                src="./images/We're to reach.png"
                alt="We're to reach.png"
              />
            </div>
          </Grid>
        </Grid>
      </Stack>

      <Footer />
    </>
  );
};

export default FAQ;
