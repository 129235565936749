import { Typography } from '@mui/material';
import React from 'react'
import { Link } from 'react-router-dom';

const PaymentSuccess = () => {
  return (
    <>
    <div className="successcard">
      <div 
      style={{borderRadius:"200px", height:"200px", width:"200px", margin:"0 auto",}}
      >
        {/* <i class="checkmark">✓</i> */}
        <img
          style={{
                  width: "100%",
                  height: "auto",
                  margin: "auto",
                }}
                src="./images/success gif.gif"
                alt="success gif"
              />
      </div>
        <h1>Success</h1> 
        <p style={{marginBottom:'8px'}}>We received your purchase request;<br/> we'll be in touch shortly!</p>

        <Typography>
            <Link
              style={{ textDecoration: "none", color: "gray" }}
              to="/">
              <button
                style={{
                  width: "30%",
                  height: "60px",
                  fontSize: "18px",
                  margin: "auto",
                  backgroundColor: "rgb(54,225,253)",
                  backgroundImage:
                    "linear-gradient(to right top, #36e1fd, #36e1fd, #9787ff, #f055ed, #fc17fa)",
                }}
                className="btn-hover color-3">
                Home
                {/* <p style={{margin:'auto',color:'none',textAlign:'center',padding:'2px'}}>Coming Soon...</p> */}
              </button>
            </Link>
          </Typography>
      </div>
    </>
  )
}

export default PaymentSuccess;