import React, { useState, useEffect } from "react";
import HTMLFlipBook from "react-pageflip";
import { Document, Page, pdfjs } from "react-pdf";
import Navbar1 from "../Navbar1";
import Footer from "../footer";
import { Button, Stack, TextField, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import ConfigAPIURL from "../../config/ConfigApiUrl";
import utilController from "../../utils/Utilcontroller";
import credentails from "../../config/Credentils";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";
import jwt_decode from "jwt-decode";
import { SnackbarProvider, enqueueSnackbar } from "notistack";



pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function Flipbook(props) {
  const location = useLocation();
  console.log(location, "this is location");
  const [numPages, setNumPages] = useState(null);
  const [isMobile, setIsMobile] = useState(false);
  const [showCommentSection, setShowCommentSection] = useState(false);
  const [commentSubmitMessage, setCommentSubmitMessage] = useState("");
  const [isSignInRequired, setIsSignInRequired] = useState(false);

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }

  useEffect(() => {
    setIsMobile(window.innerWidth <= 768);
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  function pagesList() {
    var pages = [];
    for (var i = 1; i <= numPages; i++) {
      pages.push(
        <div key={i}>
          <Page pageNumber={i} width={isMobile ? window.innerWidth : 500} />
        </div>
      );
    }
    return pages;
  }

  const [shareUrl, setShareUrl] = useState(window.location.href);
  const [likeCount, setLikeCount] = useState(0);
  const [viewCount, setViewCount] = useState(0);
  const [shareCount, setShareCount] = useState(0);
  const [comments, setComments] = useState([]);

  const [userComment, setUserComment] = useState("");
  const [userName, setUserName] = useState(null);
  const [postDetails, setPostDetails] = useState(null);

  const [postSpecificDetails, setSpecificDetails] = useState(null);

  const [userProfilePicture, setUserProfilePicture] = useState(null);


  useEffect(() => {
    fetchPostDEtails();
    fetchLikeList();
  }, []);

  const fetchPostDEtails = () => {
    const user_id = utilController.getQueryParams(location, "user");

    try {
      var formdata = new FormData();
      formdata.append("user_id", user_id);

      var requestOptions = {
        method: "POST",
        body: formdata,
        redirect: "follow",
      };

      fetch(ConfigAPIURL.induvidualPostDetails, requestOptions)
        .then((response) => response.json())
        .then((value) => {
          setSpecificDetails(value?.result);
        })
        .catch((err) => console.log(err));
    } catch (err) {}
  };
  const fetchLikeList = () => {
    const idValue = utilController.getQueryParams(location, "id");
    var formdata = new FormData();
    formdata.append("id", idValue);
    // var formdata = JSON.stringify({ id: Number(idValue) });

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };

    fetch(ConfigAPIURL.postDetails, requestOptions)
      .then((response) => response.json())
      .then((value) => {
        const result = value?.result;
        setLikeCount(result?.like_count);
        setComments(result["Comment details"]);
        setShareCount(result?.share_count);
        setViewCount(result?.view_count);
        setPostDetails(result);
      })
      .catch((error) => console.log("error", error));
  };

  
  

  const fetchCountData = (type) => {
    // Replace 'http://backend.lifehisto.com/api/{type}/list/' with your actual API endpoint
    return fetch(`https://backend.lifehisto.com/api/${type}/list/`)
      .then((response) => response.json())
      .catch((error) => {
        console.error(`Error fetching ${type} count:`, error);
        throw error;
      });
  };

  useEffect(() => {
    if (!userName) return;
    increaseCount("view");
  }, [userName]);

  const increaseCount = (type) => {
    if (!userName) return enqueueSnackbar("Please sign in with google");
    const idValue = utilController.getQueryParams(location, "id");
    const user = postDetails?.user;

    var formdata = new FormData();
    formdata.append("user", user);
    if (type === "like") {
      formdata.append("like_user_name", userName);
      formdata.append("like_picture", idValue);
    }
    if (type === "comment") {
      formdata.append("comment_picture", idValue);
      formdata.append("message", userComment);
      formdata.append("comment_user_name", userName);
    }
    if (type === "share") {
      formdata.append("share_picture", idValue);
      formdata.append("share_user_name", userName);
    }
    if (type === "view") {
      formdata.append("view_picture", idValue);
      formdata.append("view_user_name", userName);
    }

    var requestOptions = {
      method: "POST",
      body: formdata,
      redirect: "follow",
    };
    // Replace 'http://backend.lifehisto.com/api/{type}/create/' with your actual API endpoint
    fetch(`https://backend.lifehisto.com/api/${type}/create/`, requestOptions)
      .then(() => {
        // Update the count locally after successful increase
        if (type === "like") {
          // setLikeCount(likeCount + 1);
          fetchLikeList();
        } else if (type === "comment") {
          fetchLikeList();
          setUserComment("");
        } else if (type === "view") {
          fetchLikeList();
        } else if (type === "share") {
          fetchLikeList();
        }
      })
      .catch((error) => {
        console.error(`Error increasing ${type}:`, error);
      });
  };

  const handleShare = () => {
    if (navigator.share) {
      navigator
        .share({ url: shareUrl })
        .then(() => {
          increaseCount("share");
        })
        .catch((error) => console.error("Error sharing:", error));
    }
  };

  const handleGoogleLogin = async (response) => {
    const idToken = response?.credential;
    const decodedObj = jwt_decode(idToken);

    setUserName(decodedObj?.email);
    setUserProfilePicture(decodedObj?.picture); // Get the user's profile picture

    enqueueSnackbar("Logged in successfully!"); // Show a success notification
  };

  const handleCommentSubmit = () => {
    if (!userName) {
      setIsSignInRequired(true); // Set the flag to show sign-in message
    } else if (userComment.trim() !== "") {
      increaseCount("comment");
      setCommentSubmitMessage("Comment posted"); // Show success message
    } else {
      setCommentSubmitMessage("Please write a comment"); // Show error message
    }
  };


  return (
    <>
      <SnackbarProvider />
      <Stack className="profile-bg"
        // style={{
        //   background:
        //     "linear-gradient(45deg, #d2001a, #7462ff, #f482ff, #23d5ab)",
        //   backgroundSize: "300% 300%",
        //   animation: "color 12s ease-in-out infinite",
        // }}
      >
        <Navbar1 />
        
        <Typography id="animate-charcter2"
          style={{
            fontSize: "50px",
            textAlign: "center",
            padding: "10px",
          }}
        >
          {postDetails?.name}
        </Typography>
        <Document
          file={postSpecificDetails?.upload_picture}
          // file="https://lifehisto-s3-bucket.s3.amazonaws.com/PROFILE+PDF'S/Master+Padala+Adharva+Reddy.pdf"
          onLoadSuccess={onDocumentLoadSuccess}
          className="modal-90w"
        >
          <HTMLFlipBook
            width={isMobile ? window.innerWidth : 500}
            height={isMobile ? window.innerHeight - 200 : 707}
            showCover={true}
            mobileScrollSupport={true}
            maxShadowOpacity={0.5}
            autoSize={true}
            drawShadow={true}
            clickEventForward={true}
            useMouseEvents={true}
            renderOnlyPageLengthChange={true}
            getPageCount={1}
            className="flipbook"
          >
            {pagesList()}
          </HTMLFlipBook>
        </Document>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginBottom: "1rem",
          }}
        >
          {!userName && (
            <GoogleOAuthProvider clientId={credentails.googleLogin}>
              <GoogleLogin
                onSuccess={(credentialResponse) => {
                  handleGoogleLogin(credentialResponse);
                }}
                onError={() => {
                  console.error("Login Failed");
                }}
              />
            </GoogleOAuthProvider>
          )}
        </div>

        <div className="commentlike-section" >
          <button className="like-button" onClick={() => increaseCount("like")}>
          <img src="./images/like.png" /> ({likeCount})
          </button>

          <button
            className="comment-button"
            // onClick={() => increaseCount("commentCount")}
            onClick={() => setShowCommentSection(!showCommentSection)}
          >
            <img src="./images/comment.png" /> ({comments?.length})
          </button>

          <button className="share-button" onClick={handleShare}>
          <img src="./images/share.png" /> ({shareCount})
          </button>
          
          <button
            className="view-button"
            onClick={() => increaseCount("viewCount")}
          ><img src="./images/view.png" />
               ({viewCount})
          </button>

        </div>

        {/* User Profile Picture */}
      {/* {userName && userProfilePicture && (
        <div className="user-profile-picture" style={{borderRadius:'50%'}}>
          <img src={userProfilePicture} alt="User Profile" />
        </div>
      )} */}

        {/* Comment Section */}
        {showCommentSection && (
        <div className="comment-boxs">
         
          {/* <Typography variant="h5">Comments</Typography> */}
         
          {isSignInRequired && !userName && (
            <Typography style={{ color: "red" }}>
              Please sign in with Google to submit a comment.
            </Typography>
          )}

          {commentSubmitMessage && (
            <Typography
              style={{
                color: commentSubmitMessage === "Comment posted" ? "blue" : "red", marginTop:'20px'
              }}
            >
              {commentSubmitMessage}
            </Typography>
          )}

          <TextField
            label="Leave a Comment"
            value={userComment}
            onChange={(e) => setUserComment(e.target.value)}
            multiline
            rows={4}
            fullWidth
            variant="outlined"
            required
            style={{
              width: "100%",
              backgroundColor: "#fff",
              margin: "25px 0px",
              color: "red",
              borderRadius: "20px",
            }}
          />
          <br />
          <Button
            className="btn-hover color-3"
            variant="contained"
            onClick={handleCommentSubmit}
            style={{marginBottom:'20px'}}
          >
            Submit
          </Button>

          


          {comments?.map((comment, index) => (
            <div className="comment-boxes"
              
              key={index}
            >
              <Typography className="comment-boxes1" style={{color: "#36e1fd",backgroundColor:'#fff' }}>
                {comment?.comment_user_name}
              </Typography>
              <Typography className="comment-boxes2" style={{ color: "#000",backgroundColor:'#fff' }}>
                {comment?.message}
              </Typography>
            </div>
          ))}

          
          
          
        </div>
        )}

        <Footer />
      </Stack>
    </>
  );
}

export default Flipbook;